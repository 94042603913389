<template>
  <div class="flex">
    <h4 v-if="countDownDisplay[0] != '00' ">{{countDownDisplay[0]}}d :</h4>
    <h4 >{{countDownDisplay[1]}}h :</h4>
    <h4 >{{countDownDisplay[2]}}m :</h4>
    <h4 >{{countDownDisplay[3]}}s</h4>
  </div>
  
</template>

<style lang="scss" scoped>
h4{
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;

  margin-left: 5px;
}
</style>

<script>
import { ref, onMounted } from "vue";
import { useQuasar } from "quasar";

export default {
  name: 'Countdown',
  props:{
    timer: Number
  },
  setup(props){
    let countDownDisplay = ref(["00", "00", "00", "00"]);
    let timer = ref(props.timer);
    let isShowConcluded = ref();
    let $q = new useQuasar();

    function _countDowWatch() {
      
      if (isShowConcluded.value == true) {
        return;
      }
      let countdown = setInterval(() => {
        if (timer.value >= 0) {
          _convertCountDown(timer.value);
          timer.value--;
        } else {
          _convertCountDown(0);
          clearInterval(countdown);

          $q.notify({
              message: "O tempo dessa promoção encerrou",
              color: "red-9",
              position: "top",
            });  

          
          document.location.reload(true); 
          
          // isShowOrientation.value = true;
          // isEndExam.value = true;
        }
      }, 1000);
    }

    function _convertCountDown(value) {
    
      let d = Math.floor(value / 86400);
      value = value % 86400;

      let h = Math.floor(value / 3600);
      value = value % 3600;
      
      let m = Math.floor(value / 60);
      value = value % 60;

      let s = Math.floor(value);
  
    
      countDownDisplay.value[0] = d >= 10 ? d : "0" + d;
      countDownDisplay.value[1] = h >= 10 ? h : "0" + h;
      countDownDisplay.value[2] = m >= 10 ? m : "0" + m;
      countDownDisplay.value[3] = s >= 10 ? s : "0" + s;

      /* let minuts = Math.floor(value / 60);
      let seconds = value % 60;
      countDownDisplay.value[0] = minuts >= 10 ? minuts : "0" + minuts;
      countDownDisplay.value[1] = seconds >= 10 ?  Math.Round(seconds) : "0" + Math.Round(seconds);*/
    }


    onMounted(()=>{
      _countDowWatch()
    });


    return{
      countDownDisplay
    }
  }
}
</script>
