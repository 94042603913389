<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="store" />
    <main class="lesson-content">
      <div class="content-table row flex q-pt-lg">
        <q-card class="card-product row no-wrap" bordered>
          <q-card-section class="self-center">
            <div class="q-mt-sm full-width">
              <div v-if="product.is_promotion">
                <div class="promo-card">
                  <span class="flex items-baseline" _ngcontent-pra-c37="">
                    Termina em:
                    <Countdown
                      v-if="promotionTimer > 0"
                      :timer="Math.abs(promotionTimer)"
                    />
                  </span>
                </div>
                <div _ngcontent-pra-c37="" class="promo-card-back"></div>
              </div>
            </div>
            <div class="col-6">
              <q-img class="col-5 image-product" :src="product.image_path" />
            </div>
          </q-card-section>
          <div class="q-pl-md logo-card">
            <q-img
              src="../../assets/logo/logo.svg"
              spinner-color="white"
              class="image-log"
            />
          </div>

          <q-card-section class="col-6 card-title-product">
            <div class="no-wrap items-center row">
              <div class="col title-product">
                {{ product.product_name }}
              </div>
            </div>

            <div class="col subtitle-product q-mt-sm">
              {{ product.categories_description }}
            </div>
            <q-separator spaced />
            <div v-if="product.is_promotion">
              <div class="col point-product-old">
                De: <s>{{ product.original_price }} moedas</s>
              </div>
              <div class="col point-product" v-if="product.is_promotion">
                Por: {{ product.price }} moedas
              </div>
            </div>

            <div class="col point-product q-mt-lg" v-else>
              {{ product.price }} moedas
            </div>
            <q-separator spaced />

            <q-card class="card-desc-product row q-pa-lg q-mt-lg" flat bordered>
              <div class="row">
                <span class="title-input-form">Descrição do Produto</span>
              </div>
              <div class="row q-mt-sm description-product">
                {{ product.product_description }}
              </div>
            </q-card>

            <q-card-section class="center">
              <DefaultBtn
                titleButton="Resgatar"
                :query="{ productId: productId }"
                route="product-details"
                icon="shopping_bag"
                @click="addOrder"
              />
            </q-card-section>
          </q-card-section>
        </q-card>
      </div>

      <q-dialog
        v-model="modalFinished"
        persistent
        transition-show="scale"
        transition-hide="scale"
      >
        <q-card class="" style="width: 400px; border-radius: 25px">
          <q-card-section class="center card-icon-finished">
            <q-icon
              size="lg"
              color="green-4"
              class="material-icons-outlined rounder-icon"
              name="check"
            />
          </q-card-section>
          <q-card-section>
            <div class="center text-title-dialog">
              O seu item foi resgatado com sucesso!
            </div>
            <div class="center text-title-dialog">
              Número do Pedido: {{ orderId }}
            </div>
          </q-card-section>

          <q-card-actions align="right" class="center bg-white text-teal">
            <q-btn
              flat
              label="Acompanhar Pedido"
              :to="{ name: 'order', query: { orderId: orderId } }"
              v-close-popup
            />
          </q-card-actions>
        </q-card>
      </q-dialog>
    </main>

    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    >
      <div class="column justify-center q-my-md">
        <div style="height: auto; width: 50px; margin: auto">
          <LoadingIcon />
        </div>
        <label>Por favor aguarde...</label>
      </div>
    </q-inner-loading>
  </div>
</template>

<script>
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import Countdown from "@/components/Countdown.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";

import WareHouseService from "@/services/WareHouseService";
import UserService from "../../services/UserServices";

import { ref, onMounted, reactive } from "vue";
import { ENV } from "@/utils/env";
import { useQuasar } from "quasar";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "ProductDetails",
  components: {
    Mural,
    DefaultBtn,
    Countdown,
    LoadingIcon,
  },
  setup() {
    let _wareHouseService = new WareHouseService();
    let breadcrumbs = ref(["Loja"]);
    let modalFinished = ref(false);
    let loading = ref(false);
    let url_aws_bucket = ENV.URL_AWS_BUCKET;
    let route = new useRoute();
    let $q = new useQuasar();
    let router = new useRouter();
    let orderId = ref(null);
    let productId = ref(0);
    let promotionTimer = ref(0);
    let product = reactive({
      product_name: null,
      provider_name: null,
      product_description: null,
      categories_description: null,
      price: null,
      original_price: null,
      image_path: null,
      is_promotion: false,
    });

    let dataProduct = reactive({
      isPromotion: false,
      price: null,
      quantity: null,
    });
    const store = useStore();

    async function _getDataOfProduct() {
      _showLoading();

      productId.value = route.query.productId;
      var response = await _wareHouseService.getProductForBuy(productId.value);

      product.product_name = response.data.product_name;
      product.provider_name = response.data.provider_name;
      product.categories_description = response.data.categories_description;
      product.price = response.data.price;
      product.product_description = response.data.description;
      product.image_path = url_aws_bucket + response.data.image_path;
      product.original_price = response.data.original_price;
      product.is_promotion = response.data.is_promotion;

      dataProduct.isPromotion = response.data.is_promotion;
      dataProduct.price = response.data.price;
      dataProduct.quantity = 1;

      promotionTimer.value =
        new Date(response.data.promotion_end_date).getTime() / 1000 -
        new Date().getTime() / 1000;

      _hideLoading();
    }

    function _showLoading() {
      loading.value = true;
    }

    function _hideLoading() {
      loading.value = false;
    }

    onMounted(async () => {
      productId.value = route.query.productId;
      _getDataOfProduct();
    });

    async function addOrder() {
      loading.value = true;

      await _wareHouseService
        .addOrder(productId.value, dataProduct)
        .then((response) => {
          loading.value = false;

          if (response.data.sales_order_id == 0) {
            alertErro(response.data.return_message);
            return;
          }

          orderId.value = response.data.sales_order_id;
          _updateUserData(dataProduct.price);
          modalFinished.value = true;
        })
        .catch((error) => {
          alertErro(error.response.data.toString());
          loading.value = false;
        });
    }

    function alertErro(message) {
      $q.notify({
        message: message,
        color: "red-9",
        position: "top",
        actions: [
          {
            label: "atualizar",
            color: "white",
            handler: () => {
              document.location.reload(true);
            },
          },
        ],
      });
    }

    //Atualizar dados ao realizar compra
    async function _updateUserData(price) {
      // let dataProfileUser = await UserService.getUserbyId(
      //   store.state.userdata.id
      // );
      store.state.userdata.balance = store.state.userdata.balance - price;
      store.commit("setUserData", store.state.userdata);
    }

    return {
      breadcrumbs,
      loading,
      addOrder,
      productId,
      promotionTimer,
      product,
      orderId,
      modalFinished,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;
}
.content-card {
  width: 100%;
  height: 206px;
  background: #f7f7f7;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.card-product {
  min-width: calc(468px - 32px);
  width: 50dvw;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
}

.card-desc-product {
  width: 400px;
  background: #ffffff;
  border: 0.1px solid rgb(112 112 112 / 12%);
  border-radius: 25px;
}

@media (min-width: 1699px) {
  .card-product {
    width: 50dvw;
    flex-direction: row;
  }
}

.title-product {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
}

.subtitle-product {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #3446d2;
}

.image-product {
  width: 395px;
  height: 425px;
  border-radius: 25px;
}

.card-title-product {
  margin-top: 4rem;
  padding-right: 3rem;
}

.point-product {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #fe5268;
}

.point-product-old {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #c9c9c9;
}

.title-input-form {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #8b8b8b;
}
.center {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.description-product {
  max-height: 6rem;
  overflow: auto;
  border-radius: 0 !important;
}

.card-icon-finished {
  margin-top: 1.2rem;
  width: 61px;
  height: 61px;
  background: #daf8e1;
  font-style: normal;
  border-radius: 30px !important;
}
.rounder-icon {
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: #45dc67;
}

.text-title-dialog {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
}

.image-log {
  height: 1.2rem;
  width: 4rem;
}

.logo-card {
  z-index: 999;
  position: absolute;
  display: block;
  right: 10px;
  top: 10px;
}

.promo-card {
  background-color: #fe5268;
  color: rgb(255, 255, 255);
  display: block;
  position: absolute;
  z-index: 999;
  padding: 0.3em 0.4em;
  top: 0.5em;
  left: -0.5em;
  border-radius: 3px 3px 3px 0;
}

.promo-card-back {
  position: absolute;
  height: 0.3em;
  background-size: cover;
  z-index: 998;
  top: 2.3em;
  left: 0;
}

.promo-card-back::before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 0;
  width: 1.05em;
  left: -0.45em;
  -webkit-transform: skewY(45deg);
  transform: skewY(45deg);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  z-index: 990;
  background-color: #000;
}
.promo-card-back::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 0.8em;
  width: 0.5em;
  left: -0.5em;
  -webkit-transform: skewY(45deg);
  transform: skewY(45deg);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  z-index: 990;
  background-color: #000;
}
</style>
